import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserState {
    access_token?: string;
    name: string;
    email: string;
    expiration: number;
    image: string;
}

let initialState: UserState = {
    access_token: undefined,
    name: "",
    email: "",
    expiration: 0,
    image: ""
};

// try to load from cookie - then check if expired
//let token = getCookie("UserAuth");

/** Load it in the case where it is saved to local storage. */
let data = window.localStorage.getItem("UserState");
if (data) {
    initialState = JSON.parse(data);
}

const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        authUser: (state, action: PayloadAction<UserState>) => {
            console.log(action.payload);
            state.access_token = action.payload.access_token;
            state.email = action.payload.email;
            state.name = action.payload.name;
            state.expiration = action.payload.expiration;
            state.image = action.payload.image;

            const data = JSON.stringify(state);
            window.localStorage.setItem("UserState", data);
        },
        logoutUser: (state) => {
            state.access_token = undefined; // this will put it back in an undefined state.
            window.localStorage.removeItem("UserState");
        }
    }
});

export const { authUser, logoutUser } = slice.actions;

export default slice.reducer;
