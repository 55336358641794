import { Grid } from "@mantine/core";
import { useEffect, useState } from "react";
import { Subsystem, getAllSubsystems } from "../api/subsystems";
import ResponsibleEngineerCard from "../components/ResponsibleEngineerCard";

export default function ResponsibleEngineerViewer() {
    const [subsystems, setSubsystems] = useState<Array<Subsystem>>([]);

    const responsibleEngineers = subsystems.reduce(
        (aggregate, current) => {
            return {
                ...aggregate,
                [current.re]: [...(aggregate[current.re] ?? []), current]
            };
        },
        {} as Record<string, Subsystem[]>
    );

    useEffect(() => {
        getAllSubsystems().then((results) => {
            // console.log('subsystems', results);
            if (results) {
                setSubsystems(results);
            }
        });
    }, []);

    return (
        <section>
            <header>
                <h3>Responsible Engineers</h3>
            </header>
            <Grid justify="space-evenly" align="space-evenly">
                {Object.keys(responsibleEngineers).map((key, index) => {
                    const name = key;
                    const systems = responsibleEngineers[key];
                    return <ResponsibleEngineerCard key={index} name={name} systems={systems} />;
                })}
            </Grid>
        </section>
    );
}
