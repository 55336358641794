import { api_client } from "./client";

export async function getRevisions() {
    try {
        const response = await api_client.get("revisions");
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        console.log(error);
    }
}
