import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Machine } from "../api/machines";
import { api_client } from "../api/client";

interface MachineState {
    loading: boolean;
    machines: Machine[];
    currentMachine: Machine | undefined;
}

let initialState: MachineState = {
    machines: [],
    loading: false,
    currentMachine: undefined
};

// previous data if already here and then load.
let data = window.localStorage.getItem("Machines");
if (data) {
    initialState = JSON.parse(data);
}

export const fetchMachines = createAsyncThunk("machines/fetchMachines", async () => {
    const response = await api_client.get("/machines/all");
    return response.data.data;
});

const slice = createSlice({
    name: "machines",
    initialState,
    reducers: {
        selectMachine: (state, action: PayloadAction<string>) => {
            let i = state.machines.findIndex((m) => m.name === action.payload);
            if (i !== -1) {
                state.currentMachine = state.machines[i];
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchMachines.fulfilled, (state, action) => {
            state.loading = false;
            state.machines = action.payload;
        });
        builder.addCase(fetchMachines.pending, (state) => {
            state.loading = true;
        });
    }
});

export const { selectMachine } = slice.actions;

export default slice.reducer;
