import { LoadingOverlay, ScrollArea, Table, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { getRevisions } from "../api/revisions";

export interface Revision {
    name: string;

    value_next: String;
    value_previous: String;
    updated_at: Date;
    revised_by: String;

    isApproved: Boolean;
}

export default function RevisionViewer() {
    const [revisions, setRevisions] = useState<Array<Revision>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        getRevisions().then((results) => {
            // console.log('revisions', results);
            if (results) {
                setRevisions(results);
            }
            setIsLoading(false);
        });
    }, []);

    const rows = revisions.map((revision, index) => {
        return (
            <tr key={index}>
                <td>{revision.name}</td>
                <td>{revision.revised_by}</td>
                <td>{revision.value_previous}</td>
                <td>{revision.value_next}</td>
                <td>{new Date(revision.updated_at).toDateString()}</td>
                <td>
                    {revision.isApproved ? (
                        <img alt={"Hello boring company"} width={20} height={20} src="/twitter_blue.png" />
                    ) : (
                        <Text>Unapproved</Text>
                    )}
                </td>
            </tr>
        );
    });

    return (
        <section>
            <header>
                <h3>Revisions</h3>
            </header>
            <ScrollArea>
                {isLoading ? (
                    <LoadingOverlay visible={true} />
                ) : (
                    <Table striped className="standard-table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Revisor</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Time</th>
                                <th>Approved?</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.length > 0 ? (
                                rows
                            ) : (
                                <tr>
                                    <td colSpan={1000}>No Revisions Available</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                )}
            </ScrollArea>
        </section>
    );
}
