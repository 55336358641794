import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api_client } from "../api/client";

type ResponsibleEngineer = { name: string; email: string };

interface ResponsibleEngineerState {
    loading: boolean;
    responsibleEngineers: ResponsibleEngineer[];
    currentRE?: ResponsibleEngineer;
}

let initialState: ResponsibleEngineerState = {
    loading: false,
    responsibleEngineers: [],
    currentRE: undefined
};

// try to load from cookie - then check if expired
//let token = getCookie("UserAuth");

/** Load it in the case where it is saved to local storage. */
let data = window.localStorage.getItem("UserState");
if (data) {
    initialState = JSON.parse(data);
}

const fetchResponsibleEngineers = createAsyncThunk("responsible_engineers/fetchAll", async () => {
    const response = await api_client.get("/responsible_engineers/all");
    return response.data.data;
});

const slice = createSlice({
    name: "responsible_engineers",
    initialState,
    reducers: {
        filterToAuthenticatedUser: (state) => {
            const storedUserState = JSON.parse(window.localStorage.getItem("UserState") ?? "{}");
            if (!storedUserState) return;
            const matchedRE = state.responsibleEngineers.find((x) => x.name === storedUserState.email);
            if (matchedRE) state.currentRE = matchedRE;
        },
        setResponsibleEngineers: (state, action: PayloadAction<ResponsibleEngineerState>) => {}
    },

    extraReducers: (builder) => {
        builder.addCase(fetchResponsibleEngineers.fulfilled, (state, action) => {
            state.loading = false;
            state.responsibleEngineers = action.payload;
        });
        builder.addCase(fetchResponsibleEngineers.pending, (state) => {
            state.loading = true;
        });
    }
});

export const { filterToAuthenticatedUser } = slice.actions;

export default slice.reducer;
