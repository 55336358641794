import { ActionIcon, Badge, Button, Group, Loader, Paper, Select, Text, Tooltip } from "@mantine/core";
import {
    IconAlertTriangleFilled,
    IconExternalLink,
    IconPlayerPauseFilled,
    IconPlayerPlayFilled,
    IconRefresh,
    IconSettingsFilled
} from "@tabler/icons-react";
import { useCallback, useEffect, useState } from "react";
import { Subsystem } from "../api/subsystems";
import { createSearchParams, useNavigate } from "react-router-dom";
import { TabKeys } from "../data/configuration-parameters-tabs";

export const red = "#F03E3E";
export const green = "#37B24D";

function getRandomKey<T extends object>(obj: T): keyof T {
    const keys = Object.keys(obj) as Array<keyof T>;
    const randomIndex = Math.floor(Math.random() * keys.length);
    return keys[randomIndex];
}

export function getRandomSubsystemInfo() {
    return getRandomKey(SubsystemsInformation);
}

const SubsystemsInformation = {
    REAL: {
        color: "orange",
        label: "real"
    },
    SIMULATED: {
        color: "green",
        label: "simulated"
    },
    MIXED: {
        color: "blue",
        label: "mixed"
    },
    PASSIVE: {
        color: "gray",
        label: "passive"
    },
    ERROR: {
        color: "red",
        label: "error"
    }
};

export default function SystemCard(props: { name: string; systems: Subsystem[] }) {
    const selectionOptions = ["All", ...props.systems.map((x) => x.name)];
    const [isFetching, setIsFetching] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(selectionOptions[0]);
    const navigate = useNavigate();

    const sync = () => {
        console.log("make api call to update all subsystems");
    };

    const viewSystems = () => {
        console.log("set query string with subsystems", props.systems, selectedOption);
        const queryString = createSearchParams({ systems: props.systems.map((x) => x.name) });
        navigate({ search: queryString.toString(), pathname: `/cpv/${TabKeys.Parameters}` });
    };

    return (
        <Paper className="system-card responsible-engineer-card">
            <div className="card-container">
                <Group>
                    <Text className="card-title">
                        {props.name} ({props.systems.length})
                    </Text>
                </Group>
                <div className="card-middle" style={{ justifyContent: "center" }}>
                    {props.systems.map((system, index) => {
                        return (
                            <Group key={index}>
                                {/* <Text align="left">{system.name}</Text> */}
                                <Badge color="pink" size="lg" style={{ textTransform: "none" }}>
                                    {system.name}
                                </Badge>
                            </Group>
                        );
                    })}
                </div>
                <Group position="apart" m={"1rem"} spacing={"xs"} noWrap>
                    <Select
                        style={{ width: "85%" }}
                        placeholder="Subsystems"
                        clearable
                        data={selectionOptions}
                        value={selectedOption}
                        onChange={(value) => setSelectedOption(value)}
                    />

                    <ActionIcon
                        onClick={sync}
                        disabled={isFetching}
                        variant="light"
                        color={!isFetching ? "green" : "red"}
                        style={{ flexGrow: 0 }}
                    >
                        <IconRefresh />
                    </ActionIcon>
                    <ActionIcon variant="filled" onClick={viewSystems}>
                        <IconExternalLink size="1.125rem" />
                    </ActionIcon>
                </Group>
            </div>
        </Paper>
    );
}
