import { api_client } from "./client";
import { Machine } from "./machines";

export type BroccoliValue = string | number | boolean;

export interface ConfigurationParameter {
    id: string;
    name: string;
    encoded_value: string;
    responsible_engineer: { name: string; email: string };
    machine: Machine;

    imported_at: Date;
    created_at: Date;
    updated_at: Date;
    updated_by: string;
}

export type ParameterUpdateDescriptor = {
    id: string;
    name: string;
    value_next: BroccoliValue;
    value_previous: BroccoliValue;
    updated_at: number;
    requested_by: string;
};

export type ParameterWriteDescriptor = ParameterUpdateDescriptor & {};

export type ParameterApprovalDescriptor = ParameterUpdateDescriptor & {
    approver_email: string;
};

export async function importAllConfigurationParameters() {
    try {
        const response = await api_client.get("configuration-parameters/import-all");
        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export async function getAllConfigurationParameters() {
    try {
        const response = await api_client.get(`configuration-parameters/all`);

        if (response.status === 200) {
            console.log(response.data);
            return response.data.data as ConfigurationParameter[];
        }
    } catch (e) {
        console.log(e);
    }
}

export async function getFilteredConfigurationParameters(filterSet: string[] = []) {
    try {
        const requestFilterPath = filterSet.length > 0 ? filterSet.join(",") : "all";
        const response = await api_client.get(`configuration-parameters/filtered/${requestFilterPath}`);

        if (response.status === 200) {
            // console.log(response.data);
            return response.data.data as ConfigurationParameter[];
        }
    } catch (e) {
        console.log(e);
    }
}

export async function writeParameters(parameterUpdateTree: Record<string, ParameterWriteDescriptor>) {
    try {
        const requestSet = Object.entries(parameterUpdateTree).map(([key, descriptor]) => {
            console.log("requesting write for", key, descriptor.name);
            console.log("store revision in db", descriptor);
            return api_client.post(`configuration-parameters/write/${descriptor.name}`, descriptor);
        });

        const responseSet = await Promise.all(requestSet.map(async (x) => (await x).data));

        return responseSet.reduce((aggregate, current) => {
            return { ...aggregate, ...current };
        }, {});
    } catch (error) {
        console.error("something went wrong writhing parameters", error);
        return Promise.reject();
    }
}

export async function approveParameters(parameterUpdateTree: Record<string, ParameterApprovalDescriptor>) {
    try {
        const requestSet = Object.entries(parameterUpdateTree).map(([key, descriptor]) => {
            console.log("requesting write for", key, descriptor.name);
            console.log("store revision in db", descriptor);
            return api_client.post(`configuration-parameters/approve/${descriptor.name}`, descriptor);
        });

        const responseSet = await Promise.all(requestSet.map(async (x) => (await x).data.data));

        return responseSet.reduce((aggregate, current) => {
            return { ...aggregate, ...current };
        }, {});
    } catch (error) {
        console.error("something went wrong approving parameters", error);
        return Promise.reject();
    }
}
