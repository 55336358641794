import { ActionIcon, Badge, Group, Paper, Select, Text } from "@mantine/core";
import {
    IconAlertTriangleFilled,
    IconExternalLink,
    IconPlayerPauseFilled,
    IconPlayerPlayFilled,
    IconSettingsFilled
} from "@tabler/icons-react";
import { useState } from "react";
import { Subsystem } from "../api/subsystems";
import { createSearchParams, useNavigate } from "react-router-dom";
import { SystemCardActions, TabKeys } from "../data/configuration-parameters-tabs";

export const red = "#F03E3E";
export const green = "#37B24D";

function getRandomKey<T extends object>(obj: T): keyof T {
    const keys = Object.keys(obj) as Array<keyof T>;
    const randomIndex = Math.floor(Math.random() * keys.length);
    return keys[randomIndex];
}

export function getRandomSubsystemInfo() {
    return getRandomKey(SubsystemsInformation);
}

const SubsystemsInformation = {
    REAL: {
        color: "orange",
        label: "real"
    },
    SIMULATED: {
        color: "green",
        label: "simulated"
    },
    MIXED: {
        color: "blue",
        label: "mixed"
    },
    PASSIVE: {
        color: "gray",
        label: "passive"
    },
    ERROR: {
        color: "red",
        label: "error"
    }
};

export default function SystemCard(props: { subsystem: Subsystem }) {
    const [isFetching, setIsFetching] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string | null>(Object.values(SystemCardActions)[0]);
    const navigate = useNavigate();

    const act =
        (overrideOption = selectedOption) =>
        () => {
            if (overrideOption === SystemCardActions.Import) {
                setIsFetching(true);
                setTimeout(() => {
                    setIsFetching(false);
                }, 2000);
            } else if (overrideOption === SystemCardActions.Write) {
                setIsFetching(true);
                setTimeout(() => {
                    setIsFetching(false);
                }, 2000);
            } else if (overrideOption === SystemCardActions.View) {
                const queryString = createSearchParams({ systems: props.subsystem.name });
                navigate(
                    { search: queryString.toString(), pathname: `/cpv/${TabKeys.Parameters}` },
                    {
                        replace: true
                    }
                );
            }
        };

    const pause = () => {
        setIsFetching(false);
    };

    return (
        <Paper className="system-card">
            <div className="card-container">
                <Text className="card-title">{props.subsystem.name}</Text>
                <div className="card-middle">
                    {!props.subsystem.isApproved ? (
                        <Group sx={{ flexDirection: "column", flexWrap: "nowrap", display: "flex" }}>
                            <IconAlertTriangleFilled size={"90px"} color={red} style={{ color: red }} />

                            <Badge color="pink" size="lg">
                                Unapproved
                            </Badge>
                        </Group>
                    ) : (
                        <Group sx={{ flexDirection: "column", flexWrap: "nowrap", display: "flex" }}>
                            <IconSettingsFilled size={"90px"} className={"card-icon " + (isFetching ? "" : "playing")} />
                            <Badge color="green" size="lg">
                                Approved
                            </Badge>
                        </Group>
                    )}
                </div>
                <Group position="apart" m={"1rem"} spacing={"xs"} noWrap>
                    <Select
                        style={{ width: "85%" }}
                        placeholder="No Sim"
                        clearable
                        data={Object.values(SystemCardActions)}
                        value={selectedOption ?? "Select Action"}
                        onChange={(value) => setSelectedOption(value)}
                    />
                    <ActionIcon variant="light" color={!isFetching ? "green" : "red"} style={{ flexGrow: 0 }}>
                        {!isFetching ? (
                            <IconPlayerPlayFilled size="1.125rem" onClick={act()} />
                        ) : (
                            <IconPlayerPauseFilled size="1.125rem" onClick={pause} />
                        )}
                    </ActionIcon>
                    <ActionIcon variant="filled">
                        <IconExternalLink size="1.125rem" onClick={act(SystemCardActions.View)} />
                    </ActionIcon>
                </Group>
            </div>
        </Paper>
    );
}
