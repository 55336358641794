import { AppShell, Navbar } from "@mantine/core";
import { MenuHeader, MainLinks, MenuFooter } from "./components/Menus";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "./routes/Error";
import { routes } from "./routes/Routes";
import { useEffect } from "react";
import { useAppDispatch } from "./state/store";
import { filterToAuthenticatedUser } from "./state/ResponsibleEngineerSlice";

export default function Home() {
    const dispatcher = useAppDispatch();

    useEffect(() => {
        // dispatcher(fetchMachines());

        dispatcher(filterToAuthenticatedUser);
    }, [dispatcher]);

    return (
        <AppShell
            padding="md"
            navbar={
                <Navbar width={{ sm: 320 }} p="xs">
                    <Navbar.Section mt="xs">
                        <MenuHeader />
                    </Navbar.Section>
                    <Navbar.Section grow mt="md">
                        <MainLinks />
                    </Navbar.Section>
                    <Navbar.Section>
                        <MenuFooter />
                    </Navbar.Section>
                </Navbar>
            }
            styles={(theme) => ({
                main: { backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.gray[0] }
            })}
        >
            <Routes>
                {routes
                    .filter((r) => r.element !== undefined)
                    .map((r) => (
                        <Route key={r.path} path={r.path} element={r.element} />
                    ))}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </AppShell>
    );
}
