import { api_client } from "./client";

export interface Subsystem {
    name: string;
    re: string;
    isApproved: boolean;
}

export async function getAllSubsystems() {
    try {
        const response = await api_client.get("subsystems/all");

        if (response.status === 200) {
            // console.log(response.data);
            return response.data.data as Subsystem[];
        }
    } catch (e) {
        console.log(e);
    }
}
