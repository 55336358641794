import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

import "./styles/App.scss";
import "./styles/login.scss";
import "./styles/table.scss";
import "./styles/system-card.scss";
import Login from "./Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Home from "./Home";
import { BrowserRouter } from "react-router-dom";
import { useAppSelector } from "./state/store";
import { ModalsProvider } from "@mantine/modals";

function App() {
    const access = useAppSelector((store) => store.user.access_token);

    return (
        <GoogleOAuthProvider clientId="538706338520-73rvkvg9ef6nmddbn8bmhs6nmecn9q9a.apps.googleusercontent.com">
            <BrowserRouter>
                <MantineProvider
                    theme={{
                        colorScheme: "dark"
                    }}
                    withGlobalStyles
                    withNormalizeCSS
                >
                    <Notifications position="top-center" />
                    <ModalsProvider>{access !== undefined ? <Home /> : <Login />}</ModalsProvider>
                </MantineProvider>
            </BrowserRouter>
        </GoogleOAuthProvider>
    );
}

export default App;
