import { Paper, TextInput, PasswordInput, Checkbox, Button, Text, Divider, Group } from "@mantine/core";
import { useGoogleLogin } from "@react-oauth/google";
import { IconBrandGoogle } from "@tabler/icons-react";
import axios from "axios";
import { useState } from "react";
import { useAppDispatch } from "./state/store";
import { authUser } from "./state/UserSlice";
import { importAllConfigurationParameters } from "./api/configuration_parameters";
import { recordLoginTime } from "./api/user";

export default function Login() {
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const dispatcher = useAppDispatch();

    window.onmessage = function (e) {
        console.log(e);
    };

    const google_login = useGoogleLogin({
        onError: () => {
            setLoading(false);
            setError("Failed to Authenticate with Google.");
        },
        onSuccess: (e) => {
            if (e) {
                console.log(e);

                axios
                    .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${e.access_token}`, {
                        headers: {
                            Authorization: `Bearer ${e.access_token}`,
                            Accept: "application/json"
                        }
                    })
                    .then((res) => {
                        // importAllConfigurationParameters();
                        // recordLoginTime(res.data.email);
                        setLoading(false);
                        if (res.data.email) {
                            dispatcher(
                                authUser({
                                    access_token: e.access_token,
                                    email: res.data.email,
                                    name: res.data.name,
                                    expiration: e.expires_in,
                                    image: res.data.picture
                                })
                            );
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        console.log(err);
                    });
            }
        }
    });

    return (
        <div className="login-wrapper">
            <Paper className="login-container" radius={0} p={30}>
                <div>
                    <div>
                        <Text
                            variant="gradient"
                            gradient={{ from: "#D57139", to: "#F49900", deg: 90 }}
                            sx={{ fontFamily: "Greycliff CF, sans-serif", marginLeft: "auto", marginRight: "auto" }}
                            ta="center"
                            fz="60px"
                            fw={900}
                            mb={25}
                            mt={25}
                        >
                            CPV
                        </Text>
                    </div>

                    <Text color="red">{error}</Text>

                    <Group grow mb="md" mt="md">
                        <Button
                            leftIcon={<IconBrandGoogle />}
                            variant="default"
                            color="gray"
                            radius={"xl"}
                            loading={loading}
                            onClick={() => {
                                setLoading(true);
                                google_login();
                            }}
                        >
                            Google
                        </Button>
                    </Group>
                    {/* 
                    <Divider label="Or continue with email" labelPosition="center" my="lg" />

                    <TextInput label="Email address" placeholder="software@boringcompany.com" size="md" />
                    <PasswordInput label="Password" placeholder="Your password" mt="md" size="md" />
                    <Checkbox label="Keep me logged in" mt="xl" size="md" />
                    <Button fullWidth mt="xl" size="md">
                        Login
                    </Button>

                    <Text ta="center" mt="md">
                        Don&apos;t have an account?{" "}
                    </Text> */}
                </div>
            </Paper>
            <div className="login-image" />
        </div>
    );
}
