export enum TabKeys {
    Subsystems = "Subsystems",
    Parameters = "Parameters",
    ResponsibleEngineers = "Responsible Engineers"
}

export enum SystemCardActions {
    View = "View",
    Import = "Import",
    Write = "Write"
}
