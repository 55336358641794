import axios from "axios";

let api_url = process.env.REACT_APP_API_URL;

if (process.env.NODE_ENV !== "development") {
    api_url = process.env.REACT_APP_PRODUCTION_API_URL;
}

export const api_client = axios.create({
    baseURL: api_url,
    timeout: 15000
});
