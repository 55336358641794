// <IconBolt size="1rem" />, color: "blue", label: "Production", disabled: false
import {
    IconBolt,
    IconTestPipe,
    IconSettingsFilled,
    IconBuildingWarehouse,
    IconBoxSeam,
    IconContainer,
    IconHome,
    IconUsersGroup,
    IconReceipt
} from "@tabler/icons-react";
import { ReactNode } from "react";
import ConfigurationParameterViewer from "./ConfigurationParameterViewer";
import { TabKeys } from "../data/configuration-parameters-tabs";
import ResponsibleEngineerViewer from "./ResponsibleEngineerViewer";
import RevisionViewer from "./RevisionViewer";

type RouteConfig = {
    name: string;
    path: string;
    icon: ReactNode;
    disabled: boolean;
    element?: ReactNode;
};

const iconsize = "1.2rem";

export const routes: RouteConfig[] = [
    {
        name: "Home",
        path: "/",
        icon: <IconHome />,
        disabled: false,
        element: <ConfigurationParameterViewer defaultTab={TabKeys.Subsystems} />
    },
    {
        name: "Parameters",
        path: `/cpv/${TabKeys.Parameters}`,
        icon: <IconSettingsFilled size={iconsize} />,
        disabled: false,
        element: <ConfigurationParameterViewer defaultTab={TabKeys.Parameters} />
    },
    {
        name: "Responsible Engineers",
        path: `/cpv/${TabKeys.ResponsibleEngineers}`,
        icon: <IconUsersGroup size={iconsize} />,
        disabled: false,
        element: <ResponsibleEngineerViewer />
    },
    {
        name: "Revisions",
        path: "/revisions",
        icon: <IconReceipt size={iconsize} />,
        disabled: false,
        element: <RevisionViewer />
    }
];
