import {
    ActionIcon,
    Box,
    Button,
    Center,
    Checkbox,
    Grid,
    Group,
    Input,
    Loader,
    LoadingOverlay,
    MultiSelect,
    ScrollArea,
    Table,
    Tabs,
    TabsValue,
    Text,
    TextInput,
    createStyles,
    rem
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import {
    getFilteredConfigurationParameters,
    getAllConfigurationParameters,
    writeParameters,
    approveParameters,
    BroccoliValue,
    ConfigurationParameter
} from "../api/configuration_parameters";
import { Subsystem, getAllSubsystems } from "../api/subsystems";
import {
    IconCheck,
    IconChevronDown,
    IconChevronUp,
    IconLoadBalancer,
    IconSearch,
    IconSelector,
    IconSettings,
    IconTable,
    IconUpload
} from "@tabler/icons-react";
import SystemCard from "../components/SubsystemCard";
import { createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TabKeys } from "../data/configuration-parameters-tabs";
import { notifications } from "@mantine/notifications";
import { modals } from "@mantine/modals";
import { useAppSelector } from "../state/store";
import debounce from "lodash.debounce";

const getTabKey = (value?: TabsValue) => {
    const tabKeyValues = Object.values(TabKeys);
    return tabKeyValues.find((x) => x === value) ?? TabKeys.Parameters;
};

function filterData(data: any[], search: string) {
    const query = search.toLowerCase().trim();
    // console.log('filtering', {query, length: data.length});
    if (query == "") return data;
    return data.filter((item) => item.name.toLowerCase().includes(query));
}

function sortData(
    data: ConfigurationParameter[],
    payload: { sortBy: keyof ConfigurationParameter | null; reversed: boolean; search: string }
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                if (typeof b[sortBy] === "string") {
                    return (b[sortBy] as any).localeCompare(a[sortBy]);
                }
            }

            return (a[sortBy] as any).localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

type OnChangeParameterValueHandler = (props: { id: string; newValue: BroccoliValue; previousValue: BroccoliValue }) => void;

export default function ConfigurationParameterViewer(props: { defaultTab?: TabKeys }) {
    const routeParams = useParams();
    const authenticatedUser = useAppSelector((state) => state.user);
    // console.log(routeParams);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [subsystems, setSubsystems] = useState<Array<Subsystem>>([]);
    const [parameters, setParameters] = useState<Array<ConfigurationParameter>>([]);
    const [selectedTab, setSelectedTab] = useState(getTabKey(props.defaultTab ?? routeParams.tabKey));
    const [showTuneableOnly, setShowTuneableOnly] = useState(true);
    const [checkedItemIDMap, setCheckedItemIDMap] = useState<Map<string, boolean>>(new Map());
    const [changedParameterValueIDMap, setChangedParameterValueIDMap] = useState<
        Map<string, { newValue: BroccoliValue; previousValue: BroccoliValue }>
    >(new Map());
    // const [maxItems, setMaxItems] = useState(333);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    // sorting

    const [sortedData, setSortedData] = useState<ConfigurationParameter[]>([]);
    const [sortBy, setSortBy] = useState<keyof ConfigurationParameter | null>(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);

    const filteredParameters = useMemo(() => {
        const systemsFilterSet = searchParams.getAll("systems");
        // console.log({ systemsFilterSet });
        const results =
            systemsFilterSet.length === 0
                ? parameters
                : parameters.filter((parameter) => {
                      return systemsFilterSet.some((key) => parameter.name.startsWith(key));
                  });
        // setSortedData(results);
        return results;
        // return results.slice(0, maxItems);
    }, [searchParams, parameters.length]);

    // const filteredParameters = filterData(parameters.slice(0, maxItems), searchQuery);

    const setSorting = (field: keyof ConfigurationParameter) => {
        setIsLoading(true);
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setReverseSortDirection(reversed);
        setSortBy(field);
    };

    // console.log({ filteredParameters, sortedData });

    const responsibleEngineers = subsystems.reduce(
        (aggregate, current) => {
            return {
                ...aggregate,
                [current.re]: [...(aggregate[current.re] ?? []), current]
            };
        },
        {} as Record<string, Subsystem[]>
    );

    useEffect(() => {
        const doWork = () => {
            setSortedData(sortData(filteredParameters, { sortBy, reversed: reverseSortDirection, search: searchQuery }));
            setIsLoading(false);
        };
        if (sortedData.length < 300) {
            doWork();
        }
        setTimeout(() => {
            setIsLoading(true);
            doWork();
        });
    }, [sortBy, reverseSortDirection, searchQuery, filteredParameters.length]);

    const fetchFilteredConfigurationParameters = () => {
        const systemsFilterSet = searchParams.getAll("systems");
        setIsLoading(true);
        getFilteredConfigurationParameters(systemsFilterSet).then((results) => {
            // console.log({results});
            if (results) {
                setParameters(results);
                setIsLoading(false);
            }
        });
    };

    useEffect(() => {
        getAllSubsystems().then((results) => {
            // console.log('subsystems', results);
            if (results) {
                setSubsystems(results);
            }
        });
        fetchFilteredConfigurationParameters();
    }, []);

    useEffect(() => {
        const tabKey = routeParams.tabKey ?? props.defaultTab;
        if (tabKey) {
            setSelectedTab(getTabKey(tabKey));
        }
    }, [routeParams.tabKey, props.defaultTab]);

    const changeTab = (tabKey: TabKeys | string | undefined) => {
        const tabValue = getTabKey(tabKey);
        setSelectedTab(tabValue);
        navigate(`/cpv/${tabValue}`);
    };

    const onCheck = (parameter: ConfigurationParameter, index: number) => {
        //console.log(parameter, index);
        checkedItemIDMap.set(parameter.id, !checkedItemIDMap.get(parameter.id) ?? true);
        setCheckedItemIDMap(new Map(checkedItemIDMap));
    };

    const getTypeSafeValue = (value: BroccoliValue) => {
        let typeCheckedValue: BroccoliValue;
        if (value == "false") typeCheckedValue = false;
        else if (value == "true") typeCheckedValue = true;
        else if (!Number.isNaN(Number(value))) typeCheckedValue = Number(value);
        else typeCheckedValue = value;
        return typeCheckedValue;
    };

    const onChangeParameterValue: OnChangeParameterValueHandler = ({ id, previousValue, newValue }) => {
        //console.log({ previousValue, newValue });
        let typeSafePreviousValue: BroccoliValue = getTypeSafeValue(previousValue);
        let typeSafeNewValue: BroccoliValue = getTypeSafeValue(newValue);
        //console.log(typeof typeSafePreviousValue, typeof typeSafeNewValue);
        if (typeof typeSafePreviousValue !== typeof typeSafeNewValue) {
            notifications.show({
                title: "Error",
                withCloseButton: true,
                withBorder: true,
                message: "Invalid type change. Refusing to update",
                color: "red"
            });
        } else if (newValue != previousValue) {
            changedParameterValueIDMap.set(id, { newValue: typeSafeNewValue, previousValue: typeSafePreviousValue });
            setChangedParameterValueIDMap(new Map(changedParameterValueIDMap));
            checkedItemIDMap.set(id, true);
            setCheckedItemIDMap(new Map(checkedItemIDMap));
        }
    };

    const debounceOnChangeParameterValue = (props: { id: string; previousValue: BroccoliValue }) =>
        debounce((event) => onChangeParameterValue({ ...props, newValue: event.target.value }), 333);

    const checkAllItems = (value: boolean) => {
        //console.log(value);
        filteredParameters.forEach((parameter) => {
            checkedItemIDMap.set(parameter.id, value);
        });
        setCheckedItemIDMap(new Map(checkedItemIDMap));
    };

    const getParameterToUpdateRecords = (ids: string[]) => {
        const checkedEntries = ids.filter((key) => checkedItemIDMap.get(key));
        const parameterToUpdateRecords = filteredParameters
            .filter((parameter) => checkedEntries.includes(parameter.id))
            .reduce((aggregate, current) => {
                return {
                    ...aggregate,
                    [current.id]: {
                        id: current.id,
                        name: current.name,
                        value_next: changedParameterValueIDMap.get(current.id)?.newValue ?? current.encoded_value,
                        value_previous: changedParameterValueIDMap.get(current.id)?.previousValue ?? current.encoded_value,
                        updated_at: Date.now(),
                        approver_email: authenticatedUser.email, // TODO: Handle discrepancy, these could be different in admin situations
                        requested_by: authenticatedUser.email
                    }
                };
            }, {});
        return parameterToUpdateRecords;
    };

    const approveSelected =
        (parameterIdSet: string[] = Array.from(checkedItemIDMap.keys())) =>
        () => {
            const chosenParameterIdSet = parameterIdSet.length > 0 ? parameterIdSet : Array.from(checkedItemIDMap.keys());
            setIsLoading(true);
            const parameterToUpdateRecords = getParameterToUpdateRecords(chosenParameterIdSet);

            approveParameters(parameterToUpdateRecords).then((responseSet) => {
                // console.log('approve parameters', responseSet);
                notifications.show({
                    message: "Parameter settings have been stored in revision"
                });
            });

            // this should simply write to the Postgres database and set the boolean value
            // display notification that write is complete
            //console.log('approve', parameterToUpdateRecords)
            setIsLoading(false);
        };

    const writeSelected =
        (parameterIdSet: string[] = []) =>
        () => {
            const chosenParameterIdSet = parameterIdSet.length > 0 ? parameterIdSet : Array.from(checkedItemIDMap.keys());
            setIsLoading(true);
            const parameterToUpdateRecords = getParameterToUpdateRecords(chosenParameterIdSet);
            // this should update the db for tracking
            // then post to ARMS API redirect endpoint
            // display notification that write is complete
            //console.log('write out ', parameterToUpdateRecords)
            writeParameters(parameterToUpdateRecords).then((responseSet) => {
                chosenParameterIdSet.forEach((id) => {
                    checkedItemIDMap.set(id, false);
                    changedParameterValueIDMap.delete(id);
                });
                setCheckedItemIDMap(new Map(checkedItemIDMap));
                setChangedParameterValueIDMap(new Map(changedParameterValueIDMap));
                console.log({ responseSet });
                notifications.show({
                    message: `${chosenParameterIdSet.length} Parameters have been written to PLC`
                });
                fetchFilteredConfigurationParameters();
                setIsLoading(false);
            });
        };

    const toggleShowTuneableOnly = () => {
        setShowTuneableOnly(!toggleShowTuneableOnly);
    };

    const forceImport = (id: string) => () => {
        console.log(id);
        // importAllConfigurationParameters();
    };

    const loadAll = () =>
        modals.openConfirmModal({
            title: "Please confirm",
            children: (
                <Group>
                    <Text>This is a very expensive operation and will cause the UI to lag.</Text>
                </Group>
            ),
            labels: { confirm: "I know what I'm doing", cancel: "Cancel" },
            onConfirm: () => {
                setIsLoading(true);
                getAllConfigurationParameters().then((results) => {
                    // console.log({results});
                    if (results) {
                        setParameters(results);
                        // setMaxItems(parameters.length);
                        setSorting(sortBy ?? "name");
                    }
                    setIsLoading(false);
                });
            }
        });

    // const rowData = sortBy ? sortedData : filteredParameters;
    // console.log({sortedData, filteredParameters})
    const rows = sortedData.map((parameter, index) => {
        // const rows = filteredParameters.map((parameter, index) => {
        const formattedRE = `${parameter.responsible_engineer.name} <${parameter.responsible_engineer.email}>`;
        // console.log(parameter, parameter.imported_at);
        return (
            <tr key={index}>
                {/* <td>{parameter.id}</td> */}
                {/* <td>{parameter.machine.name}</td> */}
                <td>
                    <Checkbox checked={checkedItemIDMap.get(parameter.id) ?? false} onChange={() => onCheck(parameter, index)} />
                </td>
                <td>{parameter.name}</td>
                <td>
                    {new Date(parameter.imported_at).toLocaleTimeString("en-us", {
                        month: "2-digit",
                        day: "2-digit"
                    })}
                </td>

                <td key={parameter.id}>
                    <Input
                        defaultValue={parameter.encoded_value}
                        onChange={debounceOnChangeParameterValue({ id: parameter.id, previousValue: parameter.encoded_value })}
                        onBlur={(event) =>
                            onChangeParameterValue({
                                id: parameter.id,
                                newValue: event.target.value,
                                previousValue: parameter.encoded_value
                            })
                        }
                    />
                    {/* {parameter.encoded_value} */}
                </td>
                <td style={{ width: 340 }}>
                    {
                        checkedItemIDMap.get(parameter.id) ? (
                            <Group>
                                <Button rightIcon={<IconCheck />} onClick={approveSelected([parameter.id])}>
                                    Approve
                                </Button>
                                <Button rightIcon={<IconUpload />} onClick={writeSelected([parameter.id])}>
                                    Write Immediately
                                </Button>
                            </Group>
                        ) : (
                            <Box sx={{ width: "100%", display: "flex" }} />
                        )
                        // (
                        //     <Group>
                        //         <Button rightIcon={<IconRefresh />} onClick={forceImport(parameter.id)}>Force Import</Button>
                        //     </Group>
                        // )
                    }
                </td>
                {/* <td>{parameter.name}</td> */}
                <td>
                    <a href={`mailto:${parameter.responsible_engineer.email}`}>{formattedRE}</a>
                </td>
                {/* <td>{Date.parse(m.updated_at)}</td> */}
            </tr>
        );
    });

    const checkedItems = Array.from(checkedItemIDMap.values()).filter((x) => x);
    const selectedItemCount = checkedItems.length;

    // console.log({ checkedItems, selectedItemCount });

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        setSearchQuery(value);
    };

    return (
        <div>
            <Tabs defaultValue={selectedTab} value={selectedTab} onTabChange={(value) => changeTab(getTabKey(value))}>
                <Tabs.List>
                    <Tabs.Tab value={TabKeys.Subsystems} icon={<IconSettings />}>
                        Subsystems
                    </Tabs.Tab>
                    <Tabs.Tab value={TabKeys.Parameters} icon={<IconTable />}>
                        Parameters ({`${sortedData.length} / ${parameters.length}`})
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value={TabKeys.Subsystems} pt="xs">
                    <section>
                        <header>
                            <h3>Subsystems</h3>
                        </header>
                        <Grid justify="space-evenly" align="space-evenly" gutter="lg">
                            {subsystems.map((system, index) => {
                                return <SystemCard key={index} subsystem={system} />;
                            })}
                        </Grid>
                    </section>
                </Tabs.Panel>
                <Tabs.Panel value={TabKeys.Parameters} pt="xs">
                    <section>
                        <Box component="header" p={16} sx={{ position: "sticky", top: 10, zIndex: 100, background: "#1A1B1E" }}>
                            <Group noWrap spacing={16} align="flex-end" mb="lg">
                                {/* <Switch label="Tuneable Only" size="md"
                                    checked={showTuneableOnly}
                                    onChange={toggleShowTuneableOnly} /> */}

                                <TextInput
                                    label="Search"
                                    placeholder="Search by any field"
                                    icon={<IconSearch size="0.9rem" stroke={1.5} />}
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                                <MultiSelect
                                    label="Subsystem"
                                    data={subsystems.map((x) => x.name)}
                                    value={searchParams.getAll("systems")}
                                    onChange={(values) => setSearchParams(createSearchParams({ systems: values }))}
                                />
                                {/* <Select label="RE" data={subsystems.map(x => x.name)} defaultValue="All" /> */}
                                <Button onClick={approveSelected()}>Approve Selected ({selectedItemCount})</Button>
                                <Button onClick={writeSelected()}>Write Selected ({selectedItemCount})</Button>
                                <Button rightIcon={<IconLoadBalancer />} onClick={loadAll}>
                                    Load All
                                </Button>
                            </Group>
                        </Box>
                        <hr />
                        <ScrollArea>
                            {isLoading ? (
                                <Loader />
                            ) : (
                                <Table striped sx={{ overflowY: "auto" }}>
                                    <thead>
                                        <tr>
                                            {/* <th>ParamID</th> */}
                                            {/* <th>MachineName</th> */}
                                            <th>
                                                <Checkbox
                                                    checked={
                                                        filteredParameters.length > 0 &&
                                                        selectedItemCount === filteredParameters.length
                                                    }
                                                    onChange={(event) => checkAllItems(event.target.checked)}
                                                />
                                            </th>
                                            <Th
                                                sorted={sortBy === "name"}
                                                reversed={reverseSortDirection}
                                                onSort={() => setSorting("name")}
                                            >
                                                Name
                                            </Th>
                                            <th>Last Imported</th>
                                            <Th
                                                sorted={sortBy === "encoded_value"}
                                                reversed={reverseSortDirection}
                                                onSort={() => setSorting("encoded_value")}
                                            >
                                                Value
                                            </Th>
                                            <th>Actions</th>
                                            <th>RE</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rows.length > 0 ? (
                                            rows
                                        ) : (
                                            <tr>
                                                <td colSpan={1000}>No matching results</td>
                                            </tr>
                                        )}
                                    </tbody>
                                    {/* <tbody>{rows.length > 0 ? rows : (isLoading ? <LoadingOverlay visible={isLoading} /> : <Text>No Results Found</Text>)}</tbody> */}
                                </Table>
                            )}
                        </ScrollArea>
                    </section>
                </Tabs.Panel>
            </Tabs>
        </div>
    );
}

const useStyles = createStyles((theme) => ({
    th: {
        padding: "0 !important"
    },

    control: {
        width: "100%",
        padding: `${theme.spacing.xs} ${theme.spacing.md}`

        /*
        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
        */
    },

    icon: {
        width: rem(21),
        height: rem(21),
        borderRadius: rem(21)
    }
}));

interface ThProps {
    children: React.ReactNode;
    reversed: boolean;
    sorted: boolean;
    onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
    const { classes } = useStyles();
    const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <th className={classes.th}>
            <Group position="left" className={classes.control}>
                <Text fw={500} fz="sm">
                    {children}
                </Text>
                <Center className={classes.icon}>
                    <ActionIcon onClick={onSort} variant="light">
                        <Icon size="0.9rem" stroke={1.5} />
                    </ActionIcon>
                </Center>
            </Group>
        </th>
    );
}
