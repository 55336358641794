import {
    UnstyledButton,
    Group,
    useMantineTheme,
    Box,
    rem,
    Text,
    ActionIcon,
    Tooltip,
    Code,
    Avatar,
    Flex,
    clsx
} from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import { ReactNode, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../state/store";
import { logoutUser } from "../state/UserSlice";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../routes/Routes";

export function MenuHeader() {
    const theme = useMantineTheme();
    return (
        <Box
            sx={{
                borderBottom: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                display: "block",
                width: "100%",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                paddingBottom: theme.spacing.md,
                color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
                userSelect: "none",
                marginTop: "0px"
            }}
        >
            <Group position="apart">
                <Text
                    variant="gradient"
                    gradient={{ from: "#D57139", to: "#F49900", deg: 90 }}
                    sx={{ fontFamily: "Greycliff CF, sans-serif" }}
                    fz={"30px"}
                    fw={900}
                >
                    CPV
                </Text>
                <Code sx={{ fontWeight: 700, backgroundColor: "#D9D9D9", color: "#141517" }}>v 0.1.0</Code>
            </Group>
        </Box>
    );
}

/**
 * I have no clue why but this is navigation without me actually caling nav ?
 */
export function MainLinks() {
    const location = useLocation();

    const link = useCallback(
        (icon: ReactNode, label: string, disabled: boolean, path: string) => {
            // TODO: change this to parse by / and get first element to determine the path or something.
            let new_selected = location.pathname === path;
            return (
                <Link to={path} key={label}>
                    <UnstyledButton
                        disabled={disabled}
                        className={clsx("menu-button", new_selected && "selected")}
                        key={label}
                        sx={(theme) => ({
                            display: "block",
                            width: "100%",
                            padding: theme.spacing.xs,
                            marginTop: "5px",
                            borderRadius: theme.radius.sm,
                            color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
                            backgroundColor: new_selected ? theme.colors.dark[6] : "auto",

                            "&:hover": {
                                backgroundColor: !disabled ? theme.colors.dark[6] : "auto",
                                userSelect: !disabled ? "auto" : "none",
                                cursor: !disabled ? "pointer" : "default"
                            }
                        })}
                    >
                        <Group>
                            {icon}
                            <Text color={"#FFFFFF"} size="md">
                                {label}
                            </Text>
                        </Group>
                    </UnstyledButton>
                </Link>
            );
        },
        [location]
    );

    return <div>{routes.map((links) => link(links.icon, links.name, links.disabled, links.path))}</div>;
}

export function MenuFooter() {
    const theme = useMantineTheme();
    const user_info = useAppSelector((store) => store.user);
    const dispatcher = useAppDispatch();

    const disconnect_button = () => {
        return (
            <Tooltip
                label="Logout"
                color="red"
                position="right-end"
                transitionProps={{ transition: "pop-top-left", duration: 250 }}
                withArrow
            >
                <ActionIcon
                    variant="filled"
                    color="red"
                    onClick={() => {
                        // TODO: Sign out basically.
                        dispatcher(logoutUser());
                    }}
                >
                    <IconLogout size={rem(18)} />
                </ActionIcon>
            </Tooltip>
        );
    };

    return (
        <Box
            sx={{
                paddingTop: theme.spacing.sm,
                borderTop: `${rem(1)} solid ${theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]}`,
                display: "block",
                width: "100%",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.sm,
                color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
                userSelect: "none"
            }}
        >
            <Flex style={{ marginTop: "10px" }} align={"center"} wrap={"nowrap"} justify={"space-between"}>
                <Box>
                    <Group spacing="sm">
                        <Avatar src={user_info.image} size={35} radius={40} />
                        <div>
                            <Text fz="sm" fw={500}>
                                {user_info.name}
                            </Text>
                            <Text c="dimmed" fz="xs">
                                {user_info.email}
                            </Text>
                        </div>
                    </Group>
                </Box>
                {disconnect_button()}
            </Flex>
        </Box>
    );
}
